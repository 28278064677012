var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"align-items-center mt-4"},[_c('span',{staticClass:"c-pointer",on:{"click":_vm.backToSettings}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/icons/previous.svg")}}),_c('label',{staticClass:"back-navigation c-pointer mx-2 text-left align-top"},[_vm._v("Back")])])]),_c('div',{staticClass:"justify-content-between register-title-content app-form pt-0"},[_c('label',{staticClass:"user-title mb-2"},[_vm._v("Policy Management")]),_c('div',{staticClass:"col-12 px-0 d-flex justify-content-between"},[(_vm.tenantPolicies.length > 0 || _vm.searchText)?_c('div',{staticClass:"col-lg-3 col-md-4"},[_c('b-input-group',{staticClass:"search-input"},[_c('b-input-group-prepend',[_c('img',{attrs:{"src":require("@/assets/icons/icon-search.svg"),"alt":"search icon"}})]),_c('b-form-input',{attrs:{"placeholder":"Search policies"},on:{"input":_vm.onChangeFilterInput},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1):_vm._e(),(_vm.tenantPolicies.length > 0)?_c('div',[(
            _vm.rolePerm &&
            _vm.rolePerm[_vm.perm.module.POLICIES] &&
            _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.CREATE)
          )?_c('b-button',{staticClass:"btn-create mt-3 c-pointer",on:{"click":_vm.addNewPolicy}},[_vm._v("Add Policy")]):_vm._e()],1):_vm._e()]),(
        _vm.tenantPolicies &&
        _vm.tenantPolicies.length > 0 &&
        _vm.rolePerm &&
        _vm.rolePerm[_vm.perm.module.POLICIES] &&
        _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.READ)
      )?_c('div',{staticClass:"tenant-policy-list mt-2 pr-2"},_vm._l((_vm.tenantPolicies),function(policy){return _c('div',{key:policy.id,staticClass:"py-2 px-3 my-3 tenant-detail"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{},[_c('div',{staticClass:"primary-user-info pl-1"},[_vm._v(" "+_vm._s(policy.policy_name)+" ")])]),_c('div',{},[_c('span',{staticClass:"mr-4 c-pointer special-info",on:{"click":function($event){return _vm.addPolicyToLocations(policy)}}},[_c('img',{staticClass:"mb-1",attrs:{"title":"Location","src":require("@/assets/icons/location-blue.png")}}),_vm._v(" Add To All Locations")]),(
                _vm.rolePerm &&
                _vm.rolePerm[_vm.perm.module.POLICIES] &&
                _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.UPDATE)
              )?_c('span',{staticClass:"c-pointer pr-3",on:{"click":function($event){return _vm.editPolicy(policy)}}},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/icons/edit-active.svg"),"alt":"edit icon"}})]):_vm._e(),(
                _vm.rolePerm &&
                _vm.rolePerm[_vm.perm.module.POLICIES] &&
                _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.DELETE)
              )?_c('span',{staticClass:"c-pointer",on:{"click":function($event){return _vm.deletePolicy(policy.id)}}},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/icons/delete.svg"),"alt":"edit icon"}})]):_vm._e()])])])}),0):_vm._e(),(_vm.tenantPolicies.length === 0 && !_vm.searchText)?_c('div',{staticClass:"text-center my-10"},[_c('img',{attrs:{"src":require("@/assets/icons/policy-management-zero-design.png")}}),_c('div',{staticClass:"py-3"},[_vm._v("No policies available. Create a new one.")]),_c('div',{staticClass:"row col-12 justify-content-center"},[_c('div',{staticClass:"px-0"},[(
              _vm.rolePerm &&
              _vm.rolePerm[_vm.perm.module.POLICIES] &&
              _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.CREATE)
            )?_c('b-button',{staticClass:"btn-create c-pointer",on:{"click":_vm.addNewPolicy}},[_vm._v("Add Policy")]):_vm._e()],1)])]):_vm._e(),(_vm.searchText !== '' && _vm.tenantPolicies.length === 0)?_c('div',{staticClass:"text-center my-10"},[_c('div',{staticClass:"text-black-50 py-3"},[_vm._v("No policies found")]),_c('div',{staticClass:"row col-12 justify-content-center"},[_c('div',{staticClass:"px-0"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.clearSearch}},[_vm._v("Clear search ")])],1)])]):_vm._e(),(
        _vm.tenantPolicies &&
        _vm.tenantPolicies.length > 0 &&
        _vm.rolePerm &&
        _vm.rolePerm[_vm.perm.module.POLICIES] &&
        _vm.rolePerm[_vm.perm.module.POLICIES].includes(_vm.perm.READ)
      )?_c('div',{staticClass:"d-flex justify-content-between pt-2"},[_c('div',[_c('b-form-select',{staticClass:"page-size",attrs:{"options":_vm.pageOptions},on:{"change":_vm.pageSizeChanged},model:{value:(_vm.tenantPolicyFilter.limit),callback:function ($$v) {_vm.$set(_vm.tenantPolicyFilter, "limit", $$v)},expression:"tenantPolicyFilter.limit"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(" Showing "+_vm._s(_vm.tenantPolicyFilter.skip + 1)+" to "+_vm._s(_vm.policiesCount > _vm.tenantPolicyFilter.skip + _vm.tenantPolicyFilter.limit ? _vm.tenantPolicyFilter.skip + _vm.tenantPolicyFilter.limit : _vm.policiesCount)+" of "+_vm._s(_vm.policiesCount)+" ")]),_c('b-pagination',{attrs:{"total-rows":_vm.policiesCount,"per-page":_vm.tenantPolicyFilter.limit,"aria-controls":"my-table"},on:{"change":function($event){return _vm.onPageChange($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('app-modal',{ref:"appModalRef",attrs:{"title":_vm.tenantPolicy && _vm.tenantPolicy.id ? 'Update Policy' : 'Create Policy'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('PolicyForm',{on:{"actionPerformed":_vm.actionPerformed}})]},proxy:true}])})],1),_c('AddPolicyLocationModal',{ref:"addPolicyLocationModal",attrs:{"size":"lg","selectedPolicy":_vm.selectedPolicy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }