





































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert, confirmGenerateMeetingCardAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import moment from "moment";
// import momenttz from "moment-timezone";

@Component({
  components: {},
})
export default class AppointmentTypeForm extends Vue {
  @Action private createAppointmentType: any;
  @Action private updateAppointmentType: any;
  @Getter public appointmentType: any;
  @Getter public appointmentTypeInitialData: any;
  @Action public setAppointmentType: any;
  @Getter public currentUser: any;

  @Prop() public dateObject: any;
  
  action = "add";
  isSubmitted = false;
  isRefreshList = false;
  $refs!: {
    appointmentTypeForm: InstanceType<typeof ValidationObserver>;
  };
  timezone = "";
  roundedCurrentDate = moment().toISOString();

  beforeMount() {
    // this.timezone = momenttz.tz.guess();
    if (this.appointmentType.id) {
      this.action = "edit";
    }
  }

  setStartTime(time: any): void {
    let sTime = moment(time).format("hh:mm A");
    let eTime = moment(moment(time).add(30, "minutes").toISOString()).format("hh:mm A");
    this.appointmentType.start_time = sTime;
    this.appointmentType.end_time = eTime;
    this.setAppointmentType(this.appointmentType);
    this.$emit('startTimeChanged', sTime);
  }

  setEndTime(time: any): void {
    let eTime = moment(time).format("hh:mm A");
    this.appointmentType.end_time = eTime;
    this.setAppointmentType(this.appointmentType);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.appointmentTypeForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.appointmentTypeForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.appointmentTypeForm.refs[
            errors[0]["key"]
          ].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.isRefreshList = true;
        if (this.action === "add") {
          this.createAppointmentType({
            data: this.appointmentType,
            callback: this.onActionPerformed,
          });
        } else {
          this.confirmUpdateAppointmentType();
        }
      }
    });
  }

  confirmUpdateAppointmentType() {
    const { id, appointment_type, start_time, end_time } = this.appointmentType;
    alert.methods.conFirmAlert(
      confirmGenerateMeetingCardAlert,
      () => {
        this.updateAppointmentType({
          data: {
            id,
            appointment_type,
            start_time,
            end_time,
            is_update_booked_meetings: false,
            formatted_start_time: moment(
              this.appointmentType.start_time,
              "hh:mm A"
            ),
            formatted_end_time: moment(
              this.appointmentType.end_time,
              "hh:mm A"
            ),
          },
          callBack: this.onActionPerformed,
        });
      },
      () => {
        this.updateAppointmentType({
          data: {
            id,
            appointment_type,
            start_time,
            end_time,
            is_update_booked_meetings: true,
            formatted_start_time: moment(
              this.appointmentType.start_time,
              "hh:mm A"
            ),
            formatted_end_time: moment(
              this.appointmentType.end_time,
              "hh:mm A"
            ),
          },
          callBack: this.onActionPerformed,
        });
      }
    );
  }

  onCancel() {
    this.isRefreshList = false;
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.onActionPerformed();
      },
      () => {}
    );
  }

  onActionPerformed() {
    this.$emit("actionPerformed", this.isRefreshList);
  }
}
