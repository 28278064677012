





























































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deleteAppointmentTypeAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import AppointmentTypeForm from "@/views/appointmentTypeForm/AppointmentTypeForm.vue"
import moment from "moment";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    AppointmentTypeForm
  }
})
export default class AppointmentTypeList extends Vue {
  @Action public getAppointmentTypes: any;
  @Action private updateAppointmentType: any;
  @Getter public appointmentType: any;
  @Getter public appointmentTypeInitialData: any;
  @Action public setAppointmentType: any;
  @Getter public appointmentTypes: any;
  @Action public getAppointmentTypesCount: any;
  @Getter public appointmentTypesCount: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  ref: any = "appModalRef";
  searchText = "";
  appointmentTypeFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: { eq: true },
    },
    order: "appointment_type ASC"
  };
  pageOptions = [10, 20];
  currentPage = 1;
  appointmentTypeWhere: any = {
    status: { eq: true }
  };
  roundedCurrentDate = moment().toISOString();
  dateObject = {
    startTime: this.roundedCurrentDate,
    endTime: moment(this.roundedCurrentDate).add(30, "minutes").toISOString(),
  };

  beforeMount() {
    this.getAppointmentTypeList();
  }

  getAppointmentTypeList() {
    this.getAppointmentTypes(this.appointmentTypeFilter);
    this.getAppointmentTypesCount(this.appointmentTypeWhere);
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.setAppointmentType(this.appointmentTypeInitialData);
    if (isPageRefresh) {
      this.getAppointmentTypeList();
    }
  }

  startTimeChanged(time: any) {
    this.dateObject.startTime = moment(time, 'hh:mm A').toISOString();
    this.dateObject.endTime = moment(time, 'hh:mm A').add(30, 'minutes').toISOString();
  }

  onChangeFilterInput() {
    if (this.searchText.length === 0 || this.searchText.length >= 3) {
      this.appointmentTypeFilter.skip = 0;
      this.appointmentTypeFilter.where.or = [
        { appointment_type: { ilike: `%${this.searchText}%` } },
      ];
      this.appointmentTypeWhere.or = [
        { appointment_type: { ilike: `%${this.searchText}%` } },
      ];
      this.getAppointmentTypeList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addNewAppointmentType() {
    let editStartDate = moment().toISOString();
    let editEndDate = moment(moment(editStartDate).add(30, "minutes").toISOString()).toISOString();
    this.dateObject = {
      startTime: editStartDate,
      endTime: editEndDate,
    };
    this.setAppointmentType(this.appointmentTypeInitialData);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  editAppointmentType(appointmentType: any) {
    let editStartDate = moment(appointmentType.start_time, "hh:mm A").toISOString();
    let editEndDate = moment(appointmentType.end_time, "hh:mm A").toISOString();
    this.dateObject = {
      startTime: editStartDate,
      endTime: editEndDate,
    };
    this.setAppointmentType(appointmentType);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  deleteAppointmentType(id: number): void {
    alert.methods.conFirmAlert(
      deleteAppointmentTypeAlert,
      () => {
        this.updateAppointmentType({data:{ id: id, status: false },callback: this.getAppointmentTypeList});
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.appointmentTypeFilter.skip = 0;
    this.getAppointmentTypeList();
  }

  onPageChange(currentPage: any) {
    this.appointmentTypeFilter.skip = (currentPage - 1) * this.appointmentTypeFilter.limit;
    this.getAppointmentTypeList();
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }
  
}
