






































import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

@Component({})
export default class RolePermissionList extends Vue {
  @Getter public currentUser: any;
  @Action public getUserTypes: any;
  @Getter public userTypes: any;

  beforeMount() {
    this.getUserTypes({ where: {status :{ gt: 0} }, fields: { id: true, type: true , slug: true}});
  }

  editUserPermissions(userType: any) {
    this.$router.push({ path: `/setting/role-permissions/update/${userType.id}` });
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }
  
}
