





























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import MultipleSelect from "@/components/formComponents/MultipleSelect/index.vue";

@Component({
  components: {
    LetterIcon,
    MultipleSelect,
  },
})
export default class AddPolicyLocationModal extends Vue {
  @Prop({ default: "xl" }) public size!: any;
  @Prop() public selectedPolicy: any;
  @Getter public currentUser: any;
  @Getter public locations: any;
  @Getter public tenantPolicy: any;
  @Action public addPolicyToLocations: any;

  isSubmitted = false;
  $refs!: {
    policyLocationForm: InstanceType<typeof ValidationObserver>;
  };
  selectedLocations: any = [];
  filteredLocations: any = [];

  beforeMount() {}

  setSelectedLocations(values: any) {
    this.selectedLocations = values;
  }

  @Watch("tenantPolicy")
  getSelectedLocation() {
    this.selectedLocations = [];
    this.tenantPolicy?.policyLocations?.forEach((element: any) => {
      this.selectedLocations.push({...element.location,input_value:element.input_value, 
        created_by: element.created_by, created_at: element.created_at});
    });
    this.selectedLocations.sort((a: any, b: any) =>
      a.name.toUpperCase() > b.name.toUpperCase()
        ? 1
        : a.name.toUpperCase() < b.name.toUpperCase()
        ? -1
        : 0
    );
  }

  @Watch("locations")
  locationsChanged() {
    setTimeout(() => {
      const ids = new Set(this.selectedLocations.map((o: any) => o.id));
      const filtered = this.locations.filter(({ id }) => !ids.has(id));
      this.filteredLocations = filtered;
    }, 300);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.policyLocationForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.policyLocationForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.policyLocationForm.refs[
            errors[0]["key"]
          ].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        const data = {
          locationIds: this.selectedLocations,
          id: this.selectedPolicy.id,
          input_value: this.selectedPolicy.input_value,
        };
        this.addPolicyToLocations({ data, callback: () => {} });
        this.hide();
      }
    });
  }

  onCancel() {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.hide();
      },
      () => {}
    );
  }

  show(): void {
    this.$bvModal.show("policy-location-modal");
  }

  hide(): void {
    this.$bvModal.hide("policy-location-modal");
  }
}
