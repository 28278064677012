


















































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deletePreferenceAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import TenantPreferenceForm from "@/views/tenantPreferenceForm/TenantPreferenceForm.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    TenantPreferenceForm
  }
})
export default class TenantPreferenceList extends Vue {
  @Action public getTenantPreferences: any;
  @Action private updateTenantPreference: any;
  @Getter public tenantPreference: any;
  @Getter public tenantPreferenceInitialData: any;
  @Action public setTenantPreference: any;
  @Getter public tenantPreferences: any;
  @Action public getTenantPreferenceCount: any;
  @Getter public preferencesCount: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  ref: any = "appModalRef";
  searchText = "";
  tenantPreferenceFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: { eq: true },
    },
    order: "preference_name ASC"
  };
  pageOptions = [10, 20];
  currentPage = 1;
  preferenceWhere: any = {
    status: { eq: true }
  };

  beforeMount() {
    this.getPreferenceList();
  }

  getPreferenceList() {
    this.getTenantPreferences(this.tenantPreferenceFilter);
    this.getTenantPreferenceCount(this.preferenceWhere);
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.setTenantPreference(this.tenantPreferenceInitialData);
    if (isPageRefresh) {
      this.getPreferenceList();
    }
  }

  onChangeFilterInput() {
    if (this.searchText.length === 0 || this.searchText.length >= 3) {
      this.tenantPreferenceFilter.skip = 0;
      this.tenantPreferenceFilter.where.or = [
        { preference_name: { ilike: `%${this.searchText}%` } },
      ];
      this.preferenceWhere.or = [
        { preference_name: { ilike: `%${this.searchText}%` } },
      ];
      this.getPreferenceList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addPreference() {
    this.setTenantPreference(this.tenantPreferenceInitialData);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  editPreference(preference: any) {
    this.setTenantPreference(preference);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  deletePreference(id: number): void {
    alert.methods.conFirmAlert(
      deletePreferenceAlert,
      () => {
        this.updateTenantPreference({data:{ id: id, status: false },callback: this.getPreferenceList});
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.tenantPreferenceFilter.skip = 0;
    this.getPreferenceList();
  }

  onPageChange(currentPage: any) {
    this.tenantPreferenceFilter.skip = (currentPage - 1) * this.tenantPreferenceFilter.limit;
    this.getPreferenceList();
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }
  
}
