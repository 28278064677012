





















































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";

@Component({
  components: {},
})
export default class PolicyForm extends Vue {
  @Action private createPolicy: any;
  @Action private updatePolicy: any;
  @Getter public tenantPolicy: any;
  @Getter public tenantPolicyInitialData: any;
  @Action public setPolicy: any;
  action = "add";
  isSubmitted = false;
  isRefreshList = false;
  $refs!: {
    tenantPolicyForm: InstanceType<typeof ValidationObserver>;
  };

  beforeMount() {    
    if (this.tenantPolicy.id) {
      this.action = "edit";
    }
  }

   isInputRequired() {
    this.tenantPolicy.is_input_required = !this.tenantPolicy.is_input_required;
    this.tenantPolicy.input_value = "";
    this.setPolicy(this.tenantPolicy);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.tenantPolicyForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        
        const errors = Object.entries(this.$refs.tenantPolicyForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.tenantPolicyForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
         this.isRefreshList = true;
        if (this.action === "add") {
          if (!this.tenantPolicy.is_input_required) {
            delete this.tenantPolicy.input_value;
            delete this.tenantPolicy.is_input_required;
          }
          this.createPolicy({
            data: this.tenantPolicy,
            callback: this.onActionPerformed});
        } else {
          delete this.tenantPolicy.created_at;
          delete this.tenantPolicy.updated_at;
          delete this.tenantPolicy.created_by;
          delete this.tenantPolicy.updated_by;
          delete this.tenantPolicy.status;
          delete this.tenantPolicy.tenantId;

          if (!this.tenantPolicy.is_input_required) {
           this.tenantPolicy.input_value = "";
          }

          if (this.tenantPolicy.is_input_required) {
            if (this.tenantPolicy.input_value === null) {
              this.tenantPolicy.input_value = "";
            }
          } 
          this.updatePolicy({data: this.tenantPolicy,
            callback: this.onActionPerformed});
          
        }
      }
    });
  }

  onCancel() {
    this.isRefreshList = false;
     alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.onActionPerformed();
      },
      () => {}
    ); 
  }

  onActionPerformed() {
    this.$emit("actionPerformed", this.isRefreshList);
  }
}
