












































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deleteHolidayAlert } from "@/utils/notificationContent";

@Component({})
export default class RolePermissionForm extends Vue {
  @Getter public currentUser: any;
  @Action public getPermissions: any;
  @Getter public userPermissions: any;
  @Action public getRolePermissions: any;
  @Getter public userRolePermission: any;
  @Action public createOrUpdateRolePermissions: any;
  @Getter public userTypes: any;

  selectedResources: any = [];
  selectedCustomOperations = new Map(); 
  showOtherActions = true;
  pageTitle = '';

  filter = {
    fields: {
      id: true,
      resourcesId: true,
      operationsId: true,
    },
    include: [
      {
        relation: "resources",
        scope: { fields: ["id", "name", "slug","display_order"], where: { status: true } },
      },
      {
        relation: "operations",
        scope: {
          fields: ["id", "name", "is_default_action","display_order"],
          where: { status: true },
        },
      },
    ],
  };

  beforeMount() {
    if (this.$route.params.id) {
      this.setpageTitle();    
      this.getPermissions( {data: this.filter, callBack: this.getRolePermission });
    }
  }

  @Watch('currentUser')
  setpageTitle() {
    let title = this.$store.state.auth.userRoles[this.$route.params.id].toLowerCase();
    title = this.$route.params.id === '4' && title.split('_')[0] || title; 
    this.pageTitle = title.charAt(0).toUpperCase() + title.slice(1);  
  }

  getRolePermission() {
    this.getRolePermissions({id: this.$route.params.id, filter: {"fields":{"id":true,"resourcesId":true,"operationsId":true},"include":[{"relation":"resources","scope":{"fields":["id","name","slug","display_order"]}},{"relation":"operations","scope":{"fields":["id","name","is_default_action","slug","display_order"]}}]}, callBack: this.onPermissionsUpdate });
  }

  getSelectedCustomActions(resourcesId: number): Array<any> {
    return this.selectedCustomOperations.get(resourcesId);
  }

  onPermissionsUpdate() {
    this.userPermissions.forEach((permission: any) => {
      if(permission.customOperations.length > 0) {
        this.selectedCustomOperations.set(permission.resourcesId, []);
      }
    });
    this.onUserRolePermissionsUpdate();
  }

  onUserRolePermissionsUpdate() {
    this.userRolePermission.rolepermissions.forEach((permission: any) => {
      this.selectedCustomOperations.set(permission.resourcesId, permission.customOperations);
      permission.operations.forEach((operations: any) => {
        this.selectedResources.push(Number(operations.permissionId));
      });
    });
    this.showOtherActions = false;
    setTimeout(() => {
      this.showOtherActions = true;
    }, 100);
  }

  customOperationsSelected(value: any, resourcesId: any) {
    this.showOtherActions = false;
    this.selectedCustomOperations.set(resourcesId, []);
    this.selectedCustomOperations.set(resourcesId, value);
    setTimeout(() => {
      this.showOtherActions = true;
    }, 100);
  }

  updateUserRolePermissions() {
    const val = Array.from(this.selectedCustomOperations.values());
    let customActionPermissionIds: number[] = [];
    val.forEach((value: any) => {
      value.forEach((ele: any) => {
        customActionPermissionIds.push(Number(ele.permissionId));
      });
    });
    const data = {
      id: this.$route.params.id,
      permissions: customActionPermissionIds.concat(this.selectedResources),
    }
    this.createOrUpdateRolePermissions({data: data, callBack: () => { this.$router.go(0)}});
  }

  backToSettings(): void {
    this.$router.push({ name: "role-permissions.index" }).catch();
  }

  onCancel(): void {
    const message = 'Are you sure you want to cancel?'
    alert.methods.conFirmAlert(
      {...deleteHolidayAlert, message },
      () => {
        this.backToSettings();
      },
      () => {}
    );
  }
}
