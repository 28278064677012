




































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deleteHolidayAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import moment from "moment";
import constants from "@/constants/_message";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
  }
})
export default class HolidayList extends Vue {
  @Action public getHolidays: any;
  @Action private updateHoliday: any;
  @Getter public holiday: any;
  @Getter public holidayInitData: any;
  @Action public setHoliday: any;
  @Getter public holidays: any;
  @Action public getHolidayCount: any;
  @Getter public holidayCount: any;
  @Getter public currentUser: any;
  @Action public deleteHolidayById: any;
  @Action public getHolidayById: any;
  @Action public createHoliday: any;
  @Getter public rolePerm: any;
  perm = _permissions;


  ref: any = "appModalRef";
  searchText = "";
  holidayFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      and: [
        {
          status: { eq: true },
          holiday_date: { gt: moment().toISOString()}
        }
      ],
    },
    order: "holiday_date ASC"
  };
  pageOptions = [10, 20];
  currentPage = 1;
  holidayWhere: any = {
    and: [
      {status: { eq: true }},
      {holiday_date: { gt: moment().toISOString()}},
    ]
  };
  roundedCurrentDate = moment().toISOString();
  dateObject = {
    startTime: this.roundedCurrentDate,
    endTime: moment(this.roundedCurrentDate).add(30, "minutes").toISOString(),
  };
  isSubmitted = false;
  action = 'add';

  beforeMount() {
    this.getHolidayList();
  }

  getHolidayList() {
    this.getHolidays(this.holidayFilter);
    this.getHolidayCount(this.holidayWhere);
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.setHoliday(
      {
        holiday_name: '',
        holiday_date: '',
      }
    );
    this.isSubmitted = false;
    if (isPageRefresh) {
      this.getHolidayList();
    }
  }

  onCancel() {
    this.actionPerformed(false);
  }

  refreshList() {
    this.actionPerformed(true);
  }

  onChangeFilterInput() {
    if (this.searchText.length === 0 || this.searchText.length >= 3) {
      this.holidayFilter.skip = 0;
      this.holidayFilter.where.or = [
        { holiday_name: { ilike: `%${this.searchText}%` } },
      ];
      this.holidayWhere.or = [
        { holiday_name: { ilike: `%${this.searchText}%` } },
      ];
      this.getHolidayList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addNewHoliday() {
    this.action = 'add';
    this.setHoliday(
      {
        holiday_name: '',
        holiday_date: '',
      }
    );
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  editHoliday(holiday: any) {
    this.action = 'edit';
    this.getHolidayById(holiday.id);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    if(this.holiday.holiday_name !== '') {
      if (this.action === "add") {
        this.createHoliday({
          data: this.holiday,
          callback: this.refreshList,
        });
      } else {
        this.updateHoliday({data: this.holiday, callback: this.refreshList });
      }
    } else {
      alert.methods.appToaster({ message: constants.HOLIDAY_NAME_MANDATORY, type: "ERROR"})
    }
  }

  deleteHoliday(id: number): void {
    alert.methods.conFirmAlert(
      deleteHolidayAlert,
      () => {
        this.deleteHolidayById({data:{ id: id },callback: this.getHolidayList});
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.holidayFilter.skip = 0;
    this.getHolidayList();
  }

  onPageChange(currentPage: any) {
    this.holidayFilter.skip = (currentPage - 1) * this.holidayFilter.limit;
    this.getHolidayList();
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }
  
}
