







































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import {
  deletePolicyAlert
} from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import PolicyForm from "@/views/policyForm/PolicyForm.vue";
import _permissions from "@/constants/_permissions";
import AddPolicyLocationModal from "@/components/addPolicyLocationModal/AddPolicyLocationModal.vue";

@Component({
  components: {
    AppModal,
    PolicyForm,
    AddPolicyLocationModal,
  },
})
export default class TenantPolicyList extends Vue {
  @Action public getPolicies: any;
  @Action private updatePolicy: any;
  @Getter public tenantPolicy: any;
  @Getter public tenantPolicyInitialData: any;
  @Action public setPolicy: any;
  @Getter public tenantPolicies: any;
  @Action public getPoliciesCount: any;
  @Getter public policiesCount: any;
  @Getter public rolePerm: any;
  @Action public getPolicy: any;
  @Action public getTenantLocation: any;
  @Getter public currentUser: any;
  perm = _permissions;

  $refs!: {
    appModalRef: HTMLFormElement;
    addPolicyLocationModal: HTMLElement;
  };
  searchText = "";
  tenantPolicyFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: { eq: true },
    },
    order: "policy_name ASC",
  };
  pageOptions = [10, 20];
  currentPage = 1;
  policyWhere: any = {
    status: { eq: true },
  };
  selectedPolicy: any = {};

  beforeMount() {
    this.getPolicyList();
  }

  getPolicyList() {
    this.getPolicies(this.tenantPolicyFilter);
    this.getPoliciesCount(this.policyWhere);
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.setPolicy(this.tenantPolicyInitialData);
    if (isPageRefresh) {
      this.getPolicyList();
    }
  }

  onChangeFilterInput() {
    if (this.searchText.length === 0 || this.searchText.length >= 3) {
      this.tenantPolicyFilter.skip = 0;
      this.tenantPolicyFilter.where.or = [
        { policy_name: { ilike: `%${this.searchText}%` } },
      ];
      this.policyWhere.or = [
        { policy_name: { ilike: `%${this.searchText}%` } },
      ];
      this.getPolicyList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addNewPolicy() {
    this.setPolicy(this.tenantPolicyInitialData);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  editPolicy(policy: any) {
    this.setPolicy(policy);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  deletePolicy(id: number): void {
    alert.methods.conFirmAlert(
      deletePolicyAlert,
      () => {
        this.updatePolicy({
          data: { id: id, status: false },
          callback: this.getPolicyList,
        });
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.tenantPolicyFilter.skip = 0;
    this.getPolicyList();
  }

  onPageChange(currentPage: any) {
    this.tenantPolicyFilter.skip =
      (currentPage - 1) * this.tenantPolicyFilter.limit;
    this.getPolicyList();
  }

  backToSettings(): void {
    this.$router.push({ name: "setting.list" }).catch();
  }

  addPolicyToLocations(policy: any) {
    const addPolicyToLocationsFilter = {
      fields: ["id"],
      include: [
        {
          relation: "policyLocations",
          scope: {
            fields: ["id", "policyId", "locationId", "input_value",'created_at','created_by'],
            include: [
              { relation: "location", scope: { fields: ["id", "name"] } },
            ],
          },
        },
      ],
    };

    this.getTenantLocation({
      fields: { id: true, name: true },
      where: {
        status: { eq: true },
        tenantId: { eq: this.currentUser.tenantId },
      },
      order: ["name ASC"],
    });
    
    this.getPolicy({
      data: { id: policy.id, filter: addPolicyToLocationsFilter },
      callback: () => {
        setTimeout(() => {
          const addPolicyLocationModal = this.$refs
            .addPolicyLocationModal as HTMLFormElement;
          addPolicyLocationModal.show();
        }, 500);
      },
    });
    this.selectedPolicy = policy;
  }
}
