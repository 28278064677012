
























































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import _ from "lodash";

@Component({
  components: {},
})
export default class MeetingRuleForm extends Vue {
  @Action public createMeetingRule: any;
  @Action public updateMeetingRule: any;
  @Action public setMeetingRule: any;
  @Getter public appointmentTypes: any;
  @Getter public meetingRule: any;
  @Getter public locations: any;
  @Getter public meetingRuleInitData: any;
  
  action = "add";
  isSubmitted = false;
  isRefreshList = false;
  $refs!: {
    meetingRuleForm: InstanceType<typeof ValidationObserver>;
  };
  repOptions: any = [
    {
      key: 'Pharma company',
      value: 'Pharma company'
    },
    {
      key: 'Reps',
      value: 'Reps'
    }
  ];
  meetingRuleLocations: any = [];

  beforeMount() {
    // this.setMeetingRule(this.meetingRuleInitData);
    if (this.meetingRule && this.meetingRule.id) {
      this.action = "edit";
    }
  }

  setLocation(value: any) {
    this.meetingRuleLocations = value;
  }

  // Below code should be used for edit meetingRule in future
  // @Watch('meetingRule')
  // getSelectedLocation() {
  //   this.meetingRuleLocations = [];
  //   this.meetingRule.location_ids.forEach((element: any) => {
  //     this.meetingRuleLocations.push(element.tenantLocation);
  //   });
  // }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.meetingRuleForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.meetingRuleForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.meetingRuleForm.refs[
            errors[0]["key"]
          ].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.isRefreshList = true;
        if (this.action === "add") {
          this.meetingRule.applies_to = 'Reps';
          this.meetingRule.duration = 12;
          const groupedById = _.groupBy(this.meetingRuleLocations, (value) => value.id);
          this.meetingRule.location_ids = Object.keys(groupedById);
          this.createMeetingRule({
            data: this.meetingRule,
            callback: this.onActionPerformed,
          });
        } else {
          delete this.meetingRule.created_at;
          delete this.meetingRule.updated_at;
          delete this.meetingRule.created_by;
          delete this.meetingRule.updated_by;
          delete this.meetingRule.status;
          delete this.meetingRule.tenantId;
          delete this.meetingRule.appointment;
          this.updateMeetingRule({
            data: this.meetingRule,
            callback: this.onActionPerformed,
          });
        }
      }
    });
  }

  onCancel() {
    this.isRefreshList = false;
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.onActionPerformed();
      },
      () => {}
    );
  }

  toggleAll(opt: any) {
    if(opt=='select') {
      this.locations.map((v:any)=>{
        this.meetingRuleLocations.push(v);
      });
    } else {
      this.locations.map(()=>{
        this.meetingRuleLocations.pop();
      });
    }
  }

  onActionPerformed() {
    this.$emit("actionPerformed", this.isRefreshList);
    this.setMeetingRule({
      applies_to: null,
      no_of_appointments: null,
      duration: null,
      location_ids: [],
      status: true,
    });
  }
}
